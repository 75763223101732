<template>
  <div class="container" style="margin-top: 100px">
    <div v-if="orderData">
      <img style="max-width: 50%; width: 360px" :src="'https://api.orgamax.de/api/'+orderData.letterElements.header[0].metaData.imageUrl" alt="Logo"/>
      <div class="header">
        <div v-html="orderData.letterElements.sender"></div>
      </div>
      <div class="grid-6-6 m-y-5">
        <div class="grid-4-6">
          <div>Customer #</div>
          <div>{{orderData.order.customerId}}-OM / {{customer.number}}</div>
          <div>c/o</div>
          <div>{{ customer.name}}</div>
          <div>Address</div>
          <div>
            {{customer.street}} <br>
            {{customer.zipCode}} {{customer.city}} <br>
            {{customer.country}}
          </div>
        </div>
        <div class="place-x-end">
          <div class="d-flex">
            <div class="p-r-2">Reference #</div>
            <div>{{orderData.order.number}}</div>
          </div>
        </div>
      </div>
      <h1>Offer {{orderData.order.id}}</h1>
      <p>Thank you for your interest! Our experts will get in touch with you to further assist you.</p>
    </div>


    <div class="grid-2-3-3-2-2 b-b-1 m-y-3 b-gray-50">
      <div class="font-md">Art. #</div>
      <div class="font-md">Article</div>
      <div class="font-md" >Description</div>
      <div class="place-x-center font-md">Amount</div>
      <div class="place-x-end font-md">Price</div>
    </div>
    <div v-for="position in positions">
      <div class="grid-2-3-3-2-2 b-b-1 m-y-3 b-gray-50">
        <div>{{position.metaData.number}}</div>
        <div class="font-strong"> {{ position.title }}</div>
        <div v-html="position.description" class="p-b-2"></div>
        <div class="place-x-center">{{position.amount}}</div>
        <div class="place-x-end">{{ formatCurrency(position.totalNet) }}</div>
      </div>
    </div>
    <div class="grid-8-2-2">
      <div></div>
      <div>Net total</div>
      <div class="place-x-end">{{ formatCurrency(orderData.order.totalNet) }}</div>
    </div>
    <div class="grid-8-2-2" v-for="(vat,percent) in orderData.order.vat">
      <div></div>
      <div>VAT {{percent}}%</div>
      <div class="place-x-end">{{ formatCurrency(vat) }}</div>
    </div>
    <div class="grid-8-2-2">
      <div></div>
      <div>Total</div>
      <div class="place-x-end font-strong">{{ formatCurrency(orderData.order.totalGross)}}</div>
    </div>

    <footer class="grid-4-4-4" v-if="orderData">
      <div class="place-x-center" v-for="footer in orderData.letterElements.footer" v-html="footer.metaData.html"></div>
    </footer>

  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import api from "@/api";
import {ref} from "vue";
import {formatCurrency} from "@/composables/currency";
import {orgamaxImage} from "@/composables/orgamax";

const route = useRoute();
const orderData = ref(null);
const positions = ref([]);
const customer = ref(null);
const knownCircles = ref([])
api.get('/store/' + route.params.hash).then(res => {
  orderData.value = res.data.data;
  customer.value = res.data.data.order.customerData;
  positions.value = res.data.data.order.positions.sort((a, b) => Number(a.metaData.number) - Number(b.metaData.number))
  console.log(res.data.data)
})

</script>

<style scoped>

</style>